import { Decimal } from '~/composables/support/decimal'
import { addSeconds, format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

export function wrapNegativeNumber(negativeNumber: string) {
    return '(' + negativeNumber + ')'
}

export function formatCurrency(amount: string): string {
    return new Decimal(amount).formatCurrency()
}

export function formatDate(date: Date): string {
    return new Intl.DateTimeFormat(undefined, {
        dateStyle: 'long',
        timeStyle: 'short'
    }).format(date)
}

export function formatRange(date: Date, date2: Date): string {
    if (
        date2.getSeconds() === 59 &&
        !(date2.getHours() === 23 && date2.getMinutes() === 59)
    ) {
        date2 = addSeconds(date2, 1)
    }

    if (isEndOfTime(date2)) {
        return formatDate(date) + ' -'
    }

    return new Intl.DateTimeFormat('en', {
        dateStyle: 'long',
        timeStyle: 'short'
    }).formatRange(date, date2)
}

// If the two dates are on the same day it returns just that date
// Otherwise it displays 'date-date'
// If end date is end of time, nothing displayed for end date
export function formatTimelessRange(startDate: Date, endDate: Date) {
    const formattedStartDate = formatTimelessDate(startDate)

    if (isEndOfTime(endDate)) {
        return formattedStartDate + ' -'
    }

    const formattedEndDate = formatTimelessDate(endDate)
    if (formattedStartDate !== formattedEndDate) {
        return formattedStartDate + ' - ' + formattedEndDate
    } else {
        return formattedStartDate
    }
}

export function formatTimelessDate(date: Date): string {
    return new Intl.DateTimeFormat(undefined, {
        dateStyle: 'long'
    }).format(date)
}

export function parseUtc(date: string): Date {
    return new Date(date + '+00:00')
}

export function parseLocal(date: string): Date {
    return new Date(date)
}

export function parseTimeless(date: string): Date {
    return new Date(date + ' 00:00:00')
}

export function toLocalIsoString(date: Date): string {
    return format(date, 'yyyy-MM-dd HH:mm:ss')
}

export function toUtcIsoString(date: Date): string {
    return format(utcToZonedTime(date, 'UTC'), 'yyyy-MM-dd HH:mm:ss')
}

export function toTimelessIso(date: Date): string {
    return format(date, 'yyyy-MM-dd')
}

export function toCreditCardExpirationDateFormat(date: Date): string {
    return format(date, 'MM/yy')
}

export function now(timeZone: string): Date {
    return utcToZonedTime(new Date(), timeZone)
}

export function endOfTime(): Date {
    return new Date('2099-01-01 00:00:00')
}

export function isEndOfTime(date: Date): boolean {
    return date.getFullYear() > 2098
}
